import React, { useState, useRef } from "react"
import { useStaticQuery, graphql } from "gatsby"
import ReCAPTCHA from "react-google-recaptcha";
import Layout from '../components/Layout/Layout';
import SEO from "../components/seo";
import Header from '../components/Header/Header-2';
import { submitActiveCampaignForm, isValidEmail, addAndRemoveDisabledCls } from '../utils/utils';
/////////////////////////////////////////////////////////////////////////////////
const getdata = graphql`
{
  wpgraphql {
    page(id: "cG9zdDo3MzY0") {
      uri
      title
      content
      seo {
        canonical
        metaDesc
        metaKeywords
        focuskw
        title
        twitterTitle
        twitterDescription
        opengraphDescription
        opengraphPublishedTime
        opengraphModifiedTime
        opengraphTitle
        opengraphType
        opengraphImage {
          sourceUrl
        }
      }
    }
  }
}
  `
const SubmitRequest = () => {
    const API_URL = 'https://api.trustyip.com';
    const captchaRef = useRef(null);
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [subject, setSubject] = useState('');
    const [file, setFile] = useState(null);
    //
    const data = useStaticQuery(getdata);
    const common = data.wpgraphql.page;
    //
    const submit = async (e) => {
        e.preventDefault();
        const token = captchaRef.current.getValue();
        if (!token) {
            alert('Please Confirm You Are Not a Robot!');
        } else {
            // check 

            if (subject && message && email) {
                // validate email
                if (!isValidEmail(email)) {
                    alert('Email is invalid!!!');
                    return;
                }
                // verify
                const response = await fetch("https://api.trustyip.com/recaptcha/verify?token=" + token);
                const responseJson = await response.json();
                if ((responseJson.data.success) || (responseJson.data['error-codes'][0] === 'timeout-or-duplicate')) {
                    // submit file first
                    const attachments = [];
                    if (file) {
                        const formDataUpload = new FormData();
                        formDataUpload.append("files", file);
                        const response = await fetch(API_URL + '/upload', {
                            method: 'POST',
                            body: formDataUpload
                        });
                        const jsonRes = await response.json();
                        if (jsonRes && jsonRes.length) {
                            attachments.push(jsonRes[0].hash + jsonRes[0].ext);
                        }
                    }

                    // submit request
                    const reqData = {
                        email_address:email,
                        subject: subject,
                        desc: message,
                        attachments: attachments,
                        site: 'powerpatent.com'
                    }

                    fetch(API_URL + '/issues/create', {
                        "method": "POST",
                        "headers": {
                            "content-type": "application/json",
                            "accept": "application/json"
                        },
                        "body": JSON.stringify(reqData)
                        })
                        .then(response => response.json())
                        .then(response => {
                            alert('Thank you for contacting us. We will feedback to you soon!!!');
                            // reset
                            setEmail('');
                            setSubject('');
                            setMessage('');
                            // Reset input file
                            setFile('');
                            document.getElementById('inpFile').value = '';
                        })
                        .catch(err => {
                            alert('There is something wrong. Please try again later!');
                            console.log(err);
                        });

                    // reset
                    captchaRef.current.reset();
                    //
                    addAndRemoveDisabledCls('reqBtn');
                }
            } else {
                alert('Please fill in all fields.');
            }
        }
    }
return (
    <>
        <Layout>
            <SEO title="Submit A Request - PowerPatent" description={'Submit a request – PowerPatent'} canonical={common.uri} seo={common.seo} />
            <Header home={false} menu='#' />
            <main>
                <section class="submit-request-title py-5">
                    <div class="container">
                        <h2 class="page-title text-white">Submit a Request</h2>
                    </div>
                </section>
                <section class="py-5">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-12 py-md-0 py-4">
                                <div class="contact-form rounded-2 shadow">
                                    <form method="post" enctype="multipart/form-data">
                                        <h2 class="contact-tagline pb-4">Submit a request</h2>
                                        <div class="row">
                                            <div class="col-md-12 pb-4">
                                                <input type="email" class="form-control rounded-0" placeholder="Email" required="true" value={email} onChange={e => setEmail(e.target.value)} />
                                            </div>
                                            <div class="col-md-12 pb-4">
                                                <input type="text" class="form-control rounded-0" placeholder="Subject" required="true" value={subject} onChange={e => setSubject(e.target.value)} />
                                            </div>
                                            <div class="col-md-12 pb-4">
                                                <textarea type="text" rows="5" class="form-control rounded-0"
                                                    placeholder="Message" value={message} onChange={e => setMessage(e.target.value)}></textarea>
                                            </div>
                                            <div class="col-md-12 pb-4">
                                                <input id="inpFile" type="file" class="form-control rounded-0" placeholder="Attachments (optional)" required="false" onChange={(e) => setFile(e.target.files[0])} />
                                            </div>

                                            <div class="col-md-12 pb-4">
                                                <ReCAPTCHA sitekey="6LetH-InAAAAAOJxzG4oeh59AVKhM3nj4eCxXXBI" ref={captchaRef} />
                                            </div>
                                        </div>
                                        <div class="pt-3 text-center">
                                            <button id="reqBtn" onClick={submit} class="btn btn-lg btn-warning px-4" style={{ minWidth: 50 + '%' }}>Send</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </Layout>
    </>
)
}
export default SubmitRequest
